import request from "@/utils/request";

// 标准库列表
export function standList(data) {
  return request({
    url: "/resources/standard/list",
    method: "post",
    data,
  });
}
// 标准库列表
export function getStandardClassify(data) {
  return request({
    url: "/resources/standard/getStandardClassify",
    method: "post",
    data,
  });
}
// 标准库详情
export function standView(params) {
  return request({
    url: "/resources/standard/insert/"+params,
    method: "post",
    data:params
  });
}
